
import { defineComponent, ref, onMounted, Ref } from 'vue'
import DataTable from 'primevue/datatable'
import { getAllTalents } from '@/api/codertalent.api'
import { Talent } from '@/models/coderTalent/talent'

export default defineComponent({
  components: {
    DataTable
  },
  setup () {
    const allTalents = ref<Array<any>>([])
    const expandedRows = ref<Array<any>>([])
    const selectedRows = ref<Array<any>>([])
    const loading = ref<boolean>(false)
    const showPreview = ref<boolean>(false)
    const talentsTable = ref()

    const findAllTalents = async () => {
      loading.value = true
      try {
        const _allTalents = await getAllTalents()
        allTalents.value = _allTalents.map((talent: Talent) => {
          return {
            name: talent.name,
            email: talent.email,
            country: talent.location.country.name,
            city: talent.location.city,
            dni: talent.personalId,
            expertice: talent.background,
            industryPreference: talent.industriesPreferences?.join(', '),
            languages: talent.languages?.map(({ name, level }: { name: string, level: string }): string => `${name}, ${level}`).join(', '),
            skills: talent.skills?.map(({ name }: { name: string }): string => name).join(', '),
            workModalities: talent.workModalities?.join(', ')
          }
        })
      } catch (e) {
        allTalents.value = []
      } finally {
        loading.value = false
      }
    }

    const exportCSV = () => {
      talentsTable.value.exportCSV()
    }

    onMounted(async () => {
      await findAllTalents()
    })

    return {
      expandedRows,
      selectedRows,
      loading,
      showPreview,
      talentsTable,
      exportCSV,
      allTalents
    }
  }
})

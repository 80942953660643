import { Api } from '@/api/api'
import { Talent } from '@/models/coderTalent/talent'
import { TalentReponse } from '@/models/coderTalent/talentResponse'

const baseURL = process.env.TALENT_API_ENDPOINT

async function getTalents(): Promise<Talent> {
  return Api.get<Talent>(`${baseURL}/talents`)
}

async function getAllTalents(): Promise<any[]> {
  return Api.get<Talent[]>(`${baseURL}/talents/report`)
}

export { getTalents, getAllTalents }
